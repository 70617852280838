import { Box, Flex, Text, HStack } from '@chakra-ui/react';
import { Link } from '@kkhs/hakari-ui';
import getYear from 'date-fns/getYear';
import {
  AI_PHARMARKET_POLICY_PATH,
  FOOTER_HEIGHT,
  PC_CONTAINER_GUTTER_HORIZONTAL,
} from '@/shared/constants';

export function Footer() {
  return (
    <Box as="footer" h={`${FOOTER_HEIGHT}px`} bg="white">
      <Flex
        justify="space-between"
        h="100%"
        align="center"
        px={PC_CONTAINER_GUTTER_HORIZONTAL}
        mx="auto"
      >
        <Text as="small" fontSize="sm">
          Copyright &copy;{getYear(new Date())} KAKEHASHI Inc. All Rights Reserved.
        </Text>
        <HStack spacing={4}>
          <Text fontSize="sm">
            <Link href="https://www.kakehashi.life/corporate.html" isExternal>
              会社情報
            </Link>
          </Text>
          <Text fontSize="sm">
            {/* hakari 用のプライバシーポリシーに変更する必要があるか要確認 */}
            <Link href="https://support.musubi.app/hc/ja/articles/20955256462745" isExternal>
              利用規約
            </Link>
          </Text>
          <Text fontSize="sm">
            <Link href="https://www.kakehashi.life/privacy-management" isExternal>
              個人情報の取り扱いについて
            </Link>
          </Text>
          <Text fontSize="sm">
            <Link href="https://www.kakehashi.life/privacy-policy" isExternal>
              個人情報保護方針
            </Link>
          </Text>
          <Text fontSize="sm">
            <Link href="https://www.kakehashi.life/external-policy" isExternal>
              顧客情報の外部送信について
            </Link>
          </Text>
          <Text fontSize="sm">
            <Link href={AI_PHARMARKET_POLICY_PATH} isExternal>
              Pharmarket 買取サービス利用条件
            </Link>
          </Text>
        </HStack>
      </Flex>
    </Box>
  );
}
