import { useQuery } from '@apollo/client';
import {
  Button,
  Divider,
  HStack,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { WarningIcon } from '@kkhs/hakari-ui';
import format from 'date-fns/format';
import { useMemo } from 'react';
import { graphql } from '@/gql';

const nsipsAgentErrorQuery = graphql(`
  query LatestNsipsAgentErrors {
    latestNsipsAgentErrors {
      id
      errorCreatedAt
      message
    }
  }
`);

// ポーリング間隔（ミリ秒）
const POLLING_INTERVAL_MS = 60000;

export function NSIPSAgentError() {
  const { data } = useQuery(nsipsAgentErrorQuery, {
    pollInterval: POLLING_INTERVAL_MS,
  });
  const hasError = useMemo(
    () => (data?.latestNsipsAgentErrors || []).length > 0,
    [data?.latestNsipsAgentErrors],
  );
  return (
    <Popover placement="bottom-end" offset={[0, 24]} isLazy>
      <PopoverTrigger>
        <VStack
          as={Button}
          variant="unstyled"
          _focus={{ boxShadow: 'none', outline: 'none' }}
          spacing={0}
          cursor={hasError ? 'cursor' : 'default'}
          pointerEvents={hasError ? 'fill' : 'none'}
        >
          <Text fontSize="xs">NSIPS</Text>
          <WarningIcon w={5} h={5} color={hasError ? 'red.500' : 'gray.200'} />
        </VStack>
      </PopoverTrigger>
      <PopoverContent w="830px" _focus={{ boxShadow: 'lg', outline: 'none' }} border="0">
        <PopoverBody p={0}>
          <Stack spacing={0} divider={<Divider />}>
            {data?.latestNsipsAgentErrors.map((error) => (
              <HStack
                key={error.id}
                align="baseline"
                px={6}
                py={2}
                spacing={4}
                justify="space-between"
              >
                <Text>{error.message}</Text>
                <Text whiteSpace="nowrap" color="slate.500">
                  {format(new Date(error.errorCreatedAt), 'yyyy/MM/dd HH:mm')}
                </Text>
              </HStack>
            ))}
          </Stack>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
