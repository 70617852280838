import { Hub } from '@aws-amplify/core';
import {
  Box,
  Divider,
  Flex,
  HStack,
  Stack,
  Menu,
  MenuButton,
  Button,
  MenuItem,
  MenuList,
  Text,
  Portal,
  Skeleton,
} from '@chakra-ui/react';
import { ImgWithLink, Typography, ArrowLeftIcon, UserIcon, ChevronDownIcon } from '@kkhs/hakari-ui';
import AnnounceKit from 'announcekit-react';
import { useRouter } from 'next/router';
import { useCallback, useMemo } from 'react';
import { repositoryContainerGetter } from '@/di/repository';
import { currentPharmacySelectors } from '@/entities/currentPharmacy';
import { useLogout } from '@/features/auth';
import { usePharmaciesAllowedToSignInQuery } from '@/gql/apollo';
import { HEADER_HEIGHT, PC_CONTAINER_GUTTER_HORIZONTAL } from '@/shared/constants';
import { pharmacyEvents } from 'src/shared/constants/pharmacy';
import { NSIPSAgentError } from './NSIPSAgentError';
import { authSelectors } from '../../../../store/auth';

type Props = {
  /** ヘッダ中央に表示するコンポーネント */
  children: React.ReactNode;
  /** ロゴを表示するか */
  showLogo?: boolean;
  /** 戻るボタン */
  showBackButton?: boolean;
};

export function Header({ children, showLogo = true, showBackButton = false }: Props) {
  const router = useRouter();
  const currentPharmacy = currentPharmacySelectors.useValue();
  const nsipsFrom = useMemo(() => currentPharmacy?.nsipsFrom, [currentPharmacy?.nsipsFrom]);
  const authPharmacyStaff = authSelectors.usePharmacyStaff();
  const logout = useLogout();
  const clearPharmacy = useCallback(
    () =>
      Hub.dispatch('currentPharmacy', {
        event: pharmacyEvents.CLEAR,
      }),
    [],
  );

  const { data } = usePharmaciesAllowedToSignInQuery({
    fetchPolicy: 'cache-first',
  });
  const pharmacyCount = data?.pharmaciesAllowedToSignIn.edges.length;

  const userMonitoringRepository = repositoryContainerGetter.userMonitoring();
  const pharmacyId = currentPharmacy?.id;
  const musubiCode = currentPharmacy?.musubiCode;

  return (
    <Box as="header" h={`${HEADER_HEIGHT}px`} bg="white">
      <Flex
        justify="space-between"
        h="100%"
        align="center"
        px={PC_CONTAINER_GUTTER_HORIZONTAL}
        mx="auto"
        as="nav"
      >
        <HStack spacing={12}>
          {showBackButton && (
            <Button variant="ghost" onClick={router.back}>
              <ArrowLeftIcon color="primary.500" w={5} h={5} />
            </Button>
          )}
          {showLogo && (
            <ImgWithLink
              href="/"
              src="/assets/svg/hakari-logo.svg"
              alt="logo"
              w="104px"
              h="32px"
              onClick={() => {
                userMonitoringRepository.sendEvent({
                  key: '共通_AI在庫ロゴ_CLICKED',
                  contexts: { pharmacyId, musubiCode },
                });
              }}
            />
          )}
          <Stack>{children}</Stack>
        </HStack>
        <Flex align="center">
          <Stack w="80px">
            <AnnounceKit widget="https://announcekit.co/widgets/v2/1d1SmI">
              <Typography variant="body" as="span">
                リリース
              </Typography>
            </AnnounceKit>
          </Stack>
          <Menu isLazy placement="bottom-end" autoSelect={false}>
            <MenuButton
              as={Button}
              variant="ghost"
              color="text.default"
              fontWeight="normal"
              _hover={{ backgroundColor: 'base.default' }}
              _active={{ backgroundColor: 'base.default' }}
              rightIcon={<ChevronDownIcon color="primary.500" w={5} h={5} />}
              pl={2}
              pr={2}
            >
              <HStack>
                <UserIcon w="24px" h="24px" color="slate.600" />
                <Box maxW={160} textAlign="left">
                  <Typography variant="body" color="slate.700" lineHeight={5} isTruncated>
                    <Skeleton isLoaded={!!authPharmacyStaff?.name} as="span">
                      {authPharmacyStaff?.name}
                    </Skeleton>
                  </Typography>
                  <Typography variant="caption" color="slate.300" isTruncated>
                    <Skeleton isLoaded={!!currentPharmacy?.name} as="span">
                      {currentPharmacy?.name}
                    </Skeleton>
                  </Typography>
                </Box>
              </HStack>
            </MenuButton>
            {/* NOTE: カレンダーに隠れてしまう。Portal を利用することで暫定対応 */}
            <Portal>
              <MenuList fontSize="md" p={0} width="234px" overflow="hidden">
                <Box px={4} py={3}>
                  <Text color="slate.700" fontSize="15px" fontWeight="bold" lineHeight={5} pt={1}>
                    <Skeleton isLoaded={!!authPharmacyStaff?.name} as="span">
                      {authPharmacyStaff?.name}
                    </Skeleton>
                  </Text>
                  <Typography variant="body2" color="slate.500" pt={2}>
                    <Skeleton isLoaded={!!currentPharmacy?.name} as="span">
                      {currentPharmacy?.name}
                    </Skeleton>
                  </Typography>
                </Box>
                <Divider />
                {pharmacyCount !== undefined && pharmacyCount > 1 && (
                  <MenuItem onClick={clearPharmacy} px={4} py={2}>
                    薬局を切り替える
                  </MenuItem>
                )}
                <Divider />
                <MenuItem onClick={logout} px={4} py={2}>
                  ログアウト
                </MenuItem>
              </MenuList>
            </Portal>
          </Menu>
          {nsipsFrom === 'agentLite' && (
            <>
              <Divider orientation="vertical" h={8} mx={5} />
              <NSIPSAgentError />
            </>
          )}
        </Flex>
      </Flex>
    </Box>
  );
}
