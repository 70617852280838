import { Fade, Flex, Box, FlexProps } from '@chakra-ui/react';
import { css } from '@emotion/css';
import Head from 'next/head';
import { useRouter } from 'next/router';
import React from 'react';
import {
  PC_MAX_WIDTH,
  PC_CONTAINER_GUTTER_HORIZONTAL,
  PC_CONTAINER_GUTTER_VERTICAL,
} from '@/shared/constants';
import { Footer } from './Footer';
import { Header } from './Header';

const wrapper = css`
  min-width: ${PC_MAX_WIDTH}px;
  min-height: 100vh;
`;

const main = css`
  width: ${PC_MAX_WIDTH}px;
  padding-left: ${PC_CONTAINER_GUTTER_HORIZONTAL}px;
  padding-right: ${PC_CONTAINER_GUTTER_HORIZONTAL}px;
  padding-top: ${PC_CONTAINER_GUTTER_VERTICAL}px;
  padding-bottom: ${PC_CONTAINER_GUTTER_VERTICAL}px;
  margin-left: auto;
  margin-right: auto;
`;

type Props = {
  children: React.ReactNode;
  localNav?: React.FunctionComponent;
  /** ヘッダーを表示するか */
  showHeader?: boolean;
  /** フッターを表示するか */
  showFooter?: boolean;
  /** ロゴを表示するか */
  showLogo?: boolean;
  /** title */
  title?: string;
  /** description */
  description?: string;
  /** 設定画面のサイドメニュー */
  settingNav?: React.FunctionComponent;
  /** 戻るボタン */
  showBackButton?: boolean;
} & FlexProps;

export function Layout({
  children,
  localNav: LocalNav,
  showHeader = true,
  showFooter = true,
  showLogo = true,
  title,
  description = 'Musubi AI在庫管理',
  settingNav: SettingNav,
  showBackButton = false,
  ...rest
}: Props) {
  const { isReady } = useRouter();
  return (
    <>
      <Head>
        <title>{title ? `${title} | AI在庫管理` : 'Musubi AI在庫管理'}</title>
        <meta property="description" content={description} />
        {/* タブレットなどでも ${PC_MAX_WIDTH}px で閲覧時と同等の見た目になるよう固定。レスポンシブ対応の際は外すこと。 */}
        <meta name="viewport" content={`width=${PC_MAX_WIDTH}`} />
      </Head>
      <Flex bg="bg.default" direction="column" className={wrapper} {...rest}>
        {showHeader && (
          <Header showLogo={showLogo} showBackButton={showBackButton}>
            {LocalNav && <LocalNav />}
          </Header>
        )}
        {SettingNav ? (
          <Flex direction="row" flex="1" className={main}>
            <Box mr={9}>
              <SettingNav />
            </Box>
            <Box flex="1">
              <Fade in={isReady}>{children}</Fade>
            </Box>
          </Flex>
        ) : (
          <Flex direction="column" flex="1" className={main}>
            <Fade in={isReady}>{children}</Fade>
          </Flex>
        )}
        {showFooter && <Footer />}
      </Flex>
    </>
  );
}
